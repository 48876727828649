<template>
  <div>
    <v-row dense>
      <v-col>
        <p>
          Holiday entitlement<br />
          Flexi: {{ person.entitlement - person.bankHolidays }}<br />
          Statutory: {{ person.bankHolidays }}<br />
          Total: {{ person.entitlement }}<br />
        </p>
      </v-col>
      <v-col v-if="person.holidayDates.length > 0">
        <p class="mb-0">
          Bank Holidays: {{ person.bankHolidays }}<br />
          Paid Holidays: {{ person.paidHolidays }}<br />
          Unpaid Holidays: {{ person.unPaidHolidays }}<br />
          Flexi paid holidays remaining: {{ (person.entitlement - (person.paidHolidays + person.bankHolidays)) }}
        </p>
      </v-col>
    </v-row>    
    <v-row dense v-if="person.holidayDates.length > 0">
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Day</th>
              <th>Added By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(info, index) in person.holidayDates" :key="`ht_${index}`">
              <td>{{ info.date | tinyDate }}</td>
              <td>
                <span v-if="info.day">1</span>
                <span v-else>1/2</span>
                &nbsp;day&nbsp;
                <span v-if="info.bankHolidays">bank holiday</span>
                <span v-else>
                  <span v-if="info.paid">paid</span>
                  <span v-else>unpaid</span>
                </span>
              </td>
              <td>{{  info.addedBy }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <hr v-if="person.sickDates.length > 0" />
    <v-row class="mt-4" dense v-if="person.sickDates.length > 0">
      <v-col>
        <p class="mb-0">
          Sick dates in 52 weeks:<br />
          Paid Sick: {{ person.paidSick }}
        </p>
      </v-col>
      <v-col>
        <p class="mb-0">
          <br />
          Unpaid Sick: {{ person.unPaidSick }}
        </p>
      </v-col>
    </v-row>
    <v-row dense v-if="person.sickDates.length > 0">
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Day</th>
              <th>Added By</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(info, index) in person.sickDates" :key="`ht_${index}`">
              <td>{{ info.date }}</td>
              <td>
                <span v-if="info.day">1</span>
                <span v-else>1/2</span>
                &nbsp;day&nbsp;
                <span v-if="info.paid">paid</span>
                <span v-else>unpaid</span>
              </td>
              <td>{{  info.addedBy }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <hr v-if="person.matPatDates.length > 0" />
    <v-row class="mt-4" dense v-if="person.matPatDates.length > 0">
      <v-col>
        <p class="mb-0">Paid Maternity/Paternity: {{ person.paidMatPat }}</p>
      </v-col>
      <v-col>
        <p class="mb-0">Unpaid Maternity/Paternity: {{ person.unPaidMatPat }}</p>
      </v-col>
    </v-row>
    <v-row dense v-if="person.matPatDates.length > 0">
      <v-col>
        Maternity/Paternity dates:<br />
        <span
          class="mr-4"
          v-for="(date, index) in person.matPatDates"
          :key="`hd_${index}`">
          {{ date | tinyDate }}
        </span>
      </v-col>
    </v-row>
    <hr v-if="person.matPatDates.length > 0" />
    <v-row class="mt-4" dense v-if="person.absentDates.length > 0">
      <v-col>
        <p class="mb-0">Paid Absent: {{ person.paidAbsent }}</p>
      </v-col>
      <v-col>
        <p class="mb-0">Unpaid Absent: {{ person.unPaidAbsent }}</p>
      </v-col>
    </v-row>
    <v-row dense v-if="person.absentDates.length > 0">
      <v-col>
        Absent dates:<br />
        <span
          class="mr-4"
          v-for="(date, index) in person.absentDates"
          :key="`hd_${index}`">
          {{ date | tinyDate }}
        </span>
      </v-col>
    </v-row>
    <hr v-if="person.absentDates.length > 0" />
    <v-row class="mt-4" dense v-if="person.compassionateDates.length > 0">
      <v-col>
        <p class="mb-0">Paid Compassionate: {{ person.paidCompassionate }}</p>
      </v-col>
      <v-col>
        <p class="mb-0">Unpaid Compassionate: {{ person.unPaidCompassionate }}</p>
      </v-col>
    </v-row>
    <v-row dense v-if="person.compassionateDates.length > 0">
      <v-col>
        Compassionate leave dates:<br />
        <span
          class="mr-4"
          v-for="(date, index) in person.compassionateDates"
          :key="`hd_${index}`">
          {{ date | tinyDate }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>
    
<script>
import axios from '../../../../axios';

export default {
  name: 'PlanningBoardAllowance',
  props: [
    'personId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      person: {
        entitlement: 0,
        holidayDates: [],
        sickDates: [],
        matPatDates: [],
        absentDates: [],
        compassionateDates: [],
      },
    };
  },
  methods: {
    getPerson() {
      axios.get(`/personnelPlanner/getAllowance/${this.personId}.json?token=${this.token}`)
          .then((response) => {
            this.person = response.data.person;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getPerson();
  },
};
</script>
