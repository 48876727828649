<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Manage Christmas Holidays</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="addChristmasHoliday === false">
            <v-col>
              <v-btn @click="addChristmasHoliday = true" x-small class="ml-2">Add New</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="addChristmasHoliday === true">
            <v-col>
              <v-text-field
                v-model="newChristmasHoliday.name"
                placeholder="i.e. 2023"
                label="Year"></v-text-field><br />
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newChristmasHoliday.dates"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    suffix="*"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  range
                ></v-date-picker>
              </v-menu>
              <div class="pt-2">
                <v-btn @click="saveChristmasHoliday" x-small>Save</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="christmasHolidayInEdit">
            <v-col>
              <label>Update Christmas holiday</label>
              <v-text-field v-model="christmasHolidayInEdit.year" label="Year"></v-text-field><br />
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="displayEditDate"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    suffix="*"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editDate"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <div class="pt-2">
                <v-btn @click="updateChristmasHoliday" x-small>Save Changes</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Date</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(christmasHoliday, index) in christmasHolidays" :key="`bh_${index}`">
                    <td>{{ christmasHoliday.year }}</td>
                    <td>{{ christmasHoliday.date | prettyDate }}</td>
                    <td><v-btn @click="editChristmasHoliday(index)" x-small>Edit</v-btn></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div class="text-right pa-2">
        <v-btn text @click="close">Close</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from 'date-fns';
import axios from '../../../../axios';

export default {
  name: 'PlanningBoardManageChristmasHolidays',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  data() {
    return {
      dialog: true,
      christmasHolidays: [],
      newChristmasHoliday: {
        name: '',
        dates: [],
      },
      dates: [],
      editDate: null,
      addChristmasHoliday: false,
      menu: false,
      christmasHolidayInEdit: null,
      displayEditDate: null,
    };
  },
  watch: {
    dates (value) {
      this.newChristmasHoliday.dates = [];
      for (let i = 0; value.length > i; i += 1) {
        const newDate = this.formatDate(value[i]);
        this.newChristmasHoliday.dates.push(newDate);
      }
    },
    editDate () {
      this.christmasHolidayInEdit.dates = this.editDate;
      this.displayEditDate = this.formatDate(this.editDate);
    },
  },
  methods: {
    updateChristmasHoliday() {
      const postData = {};
      postData.christmasHoliday = this.christmasHolidayInEdit;
      postData.userid = this.userid;
      axios.post(`/christmasHolidays/update.json?token=${this.token}`, postData)
        .then(() => {
          this.christmasHolidayInEdit = null;
          this.christmasHolidays = [];
          this.getChristmasHolidays();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editChristmasHoliday(index) {
      this.christmasHolidayInEdit = this.christmasHolidays[index];
      this.editDate = format(new Date(this.christmasHolidayInEdit.date), 'yyy-MM-d');
    },
    saveChristmasHoliday() {
      const postData = {};
      postData.newChristmasHoliday = this.newChristmasHoliday;
      postData.userid = this.userid;
      axios.post(`/christmasHolidays/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.addChristmasHoliday = false;
          this.newChristmasHoliday.name = '';
          this.newChristmasHoliday.dates = [];
          this.christmasHolidays.push(response.data.newChristmasHoliday);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.$emit('closeDialog');
    },
    getChristmasHolidays() {
      axios.get(`/christmasHolidays/getAll.json?token=${this.token}`)
        .then((response) => {
          this.christmasHolidays = response.data.christmasHolidays;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.getChristmasHolidays();
  },
};
</script>
