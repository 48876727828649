<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900"
  >
    <div class="bg-white pa-4">
      <Allowance :personId="personId" />
      <v-row>
        <v-col class="text-right">
          <v-btn text close @click="closeDialog()">Close</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import Allowance from './Allowance.vue';

export default {
  name: 'PlanningBoardViewAllowance',
  props: [
    'personId',
  ],
  components: {
    Allowance,
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
};
</script>