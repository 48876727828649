<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900"
  >
    <div class="bg-white pa-4">
      <v-row class="pb-2">
        <v-col>
          <h3>Add Absence Record for {{ personnelName }} on {{ date | prettyDate }}</h3>
        </v-col>
      </v-row>
      <Allowance :personId="personnelId" />
      <v-row>
        <v-col>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="absence.dates"
                label="Dates"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                suffix="*"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select :items="types" label="Type" v-model="absence.type"></v-select>
        </v-col>
      </v-row>
      <v-row v-if="absence.type === 2">
        <v-col>
          <v-select :items="doctorsNote" label="Doctors note?" v-model="absence.doctors_note"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select label="Paid?" :items="paidUnpaid" v-model="absence.paid"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select label="Full Day?" :items="fullHalfDay" v-model="absence.day"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea label="Notes" v-model="absence.note"></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="message.length > 0">
        <v-col>
          <p class="text-red text-heavy">{{ message }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn text @click="createAbsence">Save</v-btn>&nbsp;
          <v-btn text @click="closeDialog">Cancel</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import Allowance from './Allowance.vue';
import axios from '../../../../axios';

export default {
  name: 'PlanningBoardCreateAbsence',
  props: [
    'personnelId',
    'personnelName',
    'date',
  ],
  components: {
    Allowance,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      absence: {
        day: 1,
        paid: 1,
        note: '',
        type: 0,
        doctors_note: 0,
        dates: [],
      },
      dates: [],
      dialog: true,
      menu: false,
      message: '',
      doctorsNote: [
        { 'text':'Yes', 'value': 1 },
        { 'text': 'No', 'value': 0 },
      ],
      paidUnpaid: [
        { 'text':'Paid', 'value': 1 },
        { 'text': 'UnPaid', 'value': 0 },
      ],
      fullHalfDay: [
        { 'text': 'Full Day', 'value': 1 },
        { 'text': 'Half Day', 'value': 0 },
      ],
      types: [
        { 'text': 'Holiday', 'value': 1 },
        { 'text': 'Sickday', 'value': 2 },
        { 'text': 'Compassionate', 'value': 3 },
        { 'text': 'Paternity/Maternity', 'value': 4 },
        { 'text': 'AWOL', 'value': 5 },
        { 'text': 'Course', 'value': 6 },
        { 'text': 'Suspended', 'value': 7 },
        { 'text': 'Working from Home', 'value': 8 },
        { 'text': 'Stood Down', 'value': 9 },
      ],
    };
  },  
  watch: {
    dates (value) {
      this.absence.dates = [];
      for (let i = 0; value.length > i; i += 1) {
        const newDate = this.formatDate(value[i]);
        this.absence.dates.push(newDate);
      }
    },
    absence: {
      handler() {
        this.message = '';
      },
      deep: true,
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    createAbsence() {
      if (this.absence.type > 0) {
        const postData = {};
        postData.absence = this.absence;
        postData.userId = this.userid;
        postData.personnelId = this.personnelId;
        axios.post(`/personnelPlanner/createAbsence.json?token=${this.token}`, postData)
          .then((response) => {
            const absence = {};
            absence.date = this.date;
            absence.personnelId = this.personnelId;
            absence.id = response.data.absence.id;
            absence.type = response.data.absence.type;
            absence.class = response.data.absence.class;
            this.$emit('addAbsence', absence);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.message = 'Please choose a type of absence';
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
  mounted() {
    const date = new Date(this.date);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1);
    month = `0${month}`;
    month = month.slice(-2);
    let day = date.getDate();
    day = `0${day}`;
    day = day.slice(-2);
    const setDate = `${year}-${month}-${day}`;
    this.dates.push(setDate);
  },
};
</script>