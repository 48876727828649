<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <h1>Planning Board</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3 pt-0 pb-4">
        <v-btn @click="manageBankHolidays = true" x-small>Bank Holidays</v-btn>
        &nbsp;
        <v-btn @click="manageChristmasHolidays = true" x-small>Christmas Holidays</v-btn>
      </v-col>
      <v-col class="col-3 pt-0 pb-4 text-center">
        <h2>
          <v-btn @click="prevMonth" x-small>PREVIOUS MONTH</v-btn>
          &nbsp;
          <span v-if="monthName.length > 0">
            {{  monthName }}
            {{  year }}
          </span>
          <span v-else>
            Loading
          </span>
          &nbsp;
          <v-btn @click="nextMonth" x-small>NEXT MONTH</v-btn>
        </h2>
      </v-col>
      <v-col class="col-1 pt-0 pb-4">
        <v-select class="pt-0 mt-0" v-model="month" :items="months"></v-select>
      </v-col>
      <v-col class="col-1 pt-0 pb-4">
        <v-select class="pt-0 mt-0" v-model="year" :items="years"></v-select>
      </v-col>
      <v-col class="col-1 pt-0 pb-4">
        <v-btn small @click="goToDate()">Go</v-btn>
      </v-col>
      <v-col class="col-3 pt-0 pb-4">
        <h2>
          <v-btn @click="viewToday()" x-small color="blue lighten-4">Today</v-btn>
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <v-simple-table dense class="planning-board" fixed-header height="700">
          <thead>
            <tr>
              <th
                class="heading-cell"
                :class="{ today: (day === heading.date && month === thisMonth) }"
                v-for="(heading, index) in headers"
                :key="`h_${index}`">
                <div :class="{ dayoff: heading.dayOff, bankHoliday: heading.bankHoliday }">
                  {{ heading.date }}
                </div>
              </th>
            </tr>
            <tr>
              <th
                v-for="(heading, index) in headers"
                :key="`h_${index}`"
                :class="{ today: (day === heading.date && month === thisMonth) }">
                <div :class="{ dayoff: heading.dayOff, bankHoliday: heading.bankHoliday }">
                  {{ heading.day }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(person, index) in planningBoard['personnel']" :key="`p_${index}`">
              <td class="name-cell">
                {{ person.name }}
                <v-icon
                    class="icon-link float-right mr-3"
                    @click="personToView = person.id"
                    small
                    color="green">
                    mdi-eye-outline</v-icon>
              </td>
              <td
                v-for="(date, index) in person.dates"
                :key="`d_${index}`"
                :class="{ today: (String(today) === String(date.date)) }">
                <div :class="{ dayoff: date.dayOff, bankHoliday: date.bankHoliday }">
                  <v-icon
                    class="icon-link"
                    @click="absenteeId = person.id, absenteeName = person.name, absenteeDate = date.date"
                    small
                    color="green">
                    mdi-plus</v-icon>
                  <span class="noverflow" v-if="date.events">
                    <span
                      v-for="(event, index) in date.events"
                      :key="`e_${index}`"
                      class="noverflow"
                      :title="event.title"
                      :class="event.class"
                      @click="eventInEdit = event.id, eventInEditType = event.type">
                      {{ event.type }}
                      <i v-if="index > 0">(+{{ (index) }})</i>
                    </span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4 pt-2 pb-2">
        <ul class="absent-key">
          <li><span class="holiday"></span>Holiday Paid</li>
          <li><span class="holiday-unpaid"></span>Holiday Unpaid</li>
          <li><span class="holiday-half"></span>Half Holiday</li>
          <li><span class="holiday-half-unpaid"></span>Half Holiday Unpaid</li>
          <li><span class="sick-day"></span>Sick Day Paid</li>
          <li><span class="sick-day-unpaid"></span>Sick Day Unpaid</li>
          <li><span class="sick-day-half"></span>Half Sick Day Paid</li>
          <li><span class="sick-day-half-unpaid"></span>Half Sick Day Unpaid</li>
          <li><span class="compassionate-day"></span>Compassionate Day Paid</li>
          <li><span class="compassionate-day-unpaid"></span>Compassionate Day Unpaid</li>
          <li><span class="compassionate-day-half"></span>Half Compassionate Day Paid</li>
          <li><span class="compassionate-day-half-unpaid"></span>Half Compassionate Day Unpaid</li>
        </ul>
      </v-col>
      <v-col class="col-4 pt-2 pb-2">
        <ul class="absent-key">
          <li><span class="parental-day"></span>Maternity/Paternity Leave Paid</li>
          <li><span class="parental-day-unpaid"></span>Maternity/Paternity Leave Unpaid</li>
          <li><span class="parental-day-half"></span>Half Maternity/Paternity Leave Paid</li>
          <li><span class="parental-day-half-unpaid"></span>Half Maternity/Paternity Leave Unpaid</li>
          <li><span class="awol-day"></span>AWOL Paid</li>
          <li><span class="awol-day-unpaid"></span>AWOL Unpaid</li>
          <li><span class="awol-day-half"></span>Half AWOL Paid</li>
          <li><span class="awol-day-half-unpaid"></span>Half AWOL Unpaid</li>
          <li><span class="course-day"></span>Course Paid</li>
          <li><span class="course-day-unpaid"></span>Course Unpaid</li>
          <li><span class="course-day-half"></span>Half Course Paid</li>
          <li><span class="course-day-half-unpaid"></span>Half Course Unpaid</li>
        </ul>
      </v-col>
      <v-col class="col-4 pt-2 pb-2">
        <ul class="absent-key">
          <li><span class="suspended-day"></span>Suspended Paid</li>
          <li><span class="suspended-day-unpaid"></span>Suspended Unpaid</li>
          <li><span class="suspended-day-half"></span>Half Suspended Paid</li>
          <li><span class="suspended-day-half-unpaid"></span>Half Suspended Unpaid</li>
          <li><span class="wfh-day"></span>Working From Home Paid</li>
          <li><span class="wfh-day-unpaid"></span>Working From Home Unpaid</li>
          <li><span class="wfh-day-half"></span>Half Working From Home Paid</li>
          <li><span class="wfh-day-half-unpaid"></span>Half Working From Home Unpaid</li>
          <li><span class="stood-down-day"></span>Stood Down Paid</li>
          <li><span class="stood-down-day-unpaid"></span>Stood Down Unpaid</li>
          <li><span class="stood-down-day-half"></span>Half Stood Down Paid</li>
          <li><span class="stood-down-day-half-unpaid"></span>Half Stood Down Unpaid</li>
        </ul>
      </v-col>
    </v-row>
    <BankHolidays
      @closeDialog="closeManageBankHolidays()"
      v-if="manageBankHolidays === true" />
      <ChristmasHolidays
      @closeDialog="closeManageChristmasHolidays()"
      v-if="manageChristmasHolidays === true" />
    <CreateAbsence
      :personnelId="absenteeId"
      :personnelName="absenteeName"
      :date="absenteeDate"
      @addAbsence="addAbsence"
      @closeDialog="absenteeId = 0"
      v-if="absenteeId > 0" />
    <UpdateAbsence
      :absenceId="eventInEdit"
      :absenceType="eventInEditType"
      @updateAbsence="updateAbsence"
      @deleteAbsence="deleteAbsence"
      @closeDialog="eventInEdit = 0"
      v-if="eventInEdit > 0" />
    <ViewAllowance
      :personId="personToView"
      @closeDialog="personToView = 0"
      v-if="personToView > 0" />
  </v-container>
</template>

<script>
import BankHolidays from './PlanningBoard/ManageBankHolidays.vue';
import ChristmasHolidays from './PlanningBoard/ManageChristmasHolidays.vue';
import CreateAbsence from './PlanningBoard/CreateAbsence.vue';
import UpdateAbsence from './PlanningBoard/UpdateAbsence.vue';
import ViewAllowance from './PlanningBoard/ViewAllowance.vue';
import axios from '../../../axios';

export default {
  name: 'PlanningBoard',
  computed: {
    token() {
      return this.$store.state.token;
    },
    day() {
      const today = new Date();
      return today.getDate();
    },
    today() {
      const today = new Date();
      const year = today.getFullYear();
      let month = (today.getMonth() + 1);
      month = `0${month}`;
      month = month.slice(-2);
      const day = today.getDate();
      return `${year}-${month}-${day}`;
    },
    thisMonth() {
      const today = new Date();
      let month = (today.getMonth() + 1);
      month = `0${month}`;
      month = month.slice(-2);
      return month;
    },
  },
  components: {
    BankHolidays,
    ChristmasHolidays,
    CreateAbsence,
    UpdateAbsence,
    ViewAllowance,
  },
  data() {
    return {
      planningBoard: [],
      headers: [],
      year: 0,
      month: 0,
      monthName: '',
      months: [
        { value: '01', text: 'Jan' },
        { value: '02', text: 'Feb' },
        { value: '03', text: 'Mar' },
        { value: '04', text: 'Apr' },
        { value: '05', text: 'May' },
        { value: '06', text: 'Jun' },
        { value: '07', text: 'Jul' },
        { value: '08', text: 'Aug' },
        { value: '09', text: 'Sep' },
        { value: '10', text: 'Oct' },
        { value: '11', text: 'Nov' },
        { value: '12', text: 'Dec' },
      ],
      years: [
        { value: '2022', text: '2022' },
        { value: '2023', text: '2023' },
        { value: '2024', text: '2024' },
        { value: '2025', text: '2025' },
        { value: '2026', text: '2026' },
        { value: '2027', text: '2027' },
      ],
      manageBankHolidays: false,
      manageChristmasHolidays: false,
      absenteeId: 0,
      absenteeName: '',
      absenteeDate: '',
      eventInEdit: 0,
      eventInEditType: '',
      personToView: 0,
    };
  },
  methods: {
    goToDate() {
      this.getPlanningBoard();
    },
    closeManageBankHolidays() {
      this.manageBankHolidays = false;
      this.getPlanningBoard();
    },
    closeManageChristmasHolidays() {
      this.manageChristmasHolidays = false;
      this.getPlanningBoard();
    },
    deleteAbsence() {
      this.absenteeId = 0;
      this.eventInEdit = 0;
      this.planningBoard = [];
      this.getPlanningBoard();
    },
    updateAbsence() {
      this.absenteeId = 0;
      this.eventInEdit = 0;
      this.planningBoard = [];
      this.getPlanningBoard();
    },
    addAbsence() {
      this.absenteeId = 0;
      this.eventInEdit = 0;
      this.getPlanningBoard();
    },
    viewToday() {
      const today = new Date();
      this.year = today.getFullYear();
      this.month = (today.getMonth() + 1);
      this.day = today.getDate();
      this.getPlanningBoard();
    },
    prevMonth() {
      const month = parseInt(this.month);
      const year = parseInt(this.year);
      if (month === 1) {
        this.month = 12;
        this.year = year - 1;
      } else {
        this.month = month - 1;
      }
      this.monthName = '';
      this.getPlanningBoard();
    },
    nextMonth() {
      const month = parseInt(this.month);
      const year = parseInt(this.year);
      if (month === 12) {
        this.month = 1;
        this.year = year + 1;
      } else {
        this.month = month + 1;
      }
      this.monthName = '';
      this.getPlanningBoard();
    },
    getPlanningBoard() {
      axios.get(`/personnelPlanner/displayBoard/${this.year}/${this.month}.json?token=${this.token}`)
        .then((response) => {
          this.headers = response.data.headers;
          this.planningBoard = response.data.planningBoard;
          this.year = response.data.year;
          this.month = response.data.month;
          this.monthName = response.data.monthName;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPlanningBoard();
  },
};
</script>

<style lang="scss">
  .dayoff {
    background-color: #DDD;
    border: 3px groove #BBB;
  }
  .planning-board {
    table {
      table-layout: fixed;
    
      th, td {
        position: relative;
        padding: 0!important;
        width: 58px;
        border: 1px solid #CCC;
        font-size: 12px!important;
        &.heading-cell:first-child {
         width: 200px;
        }
        &.name-cell {
          width: 200px;
          text-align: center;
        }
        div {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          padding: 3px;
          overflow: hidden;

          span span {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            text-align: center;
            overflow: scroll;
            cursor: pointer;
          }
        }
        div.bankHoliday span span.holiday {
          background-color: #B2DFDB;
          border: 3px solid #B2DFDB;

          &::before {
            content: 'B';
          }
        }
      }

      th {
        text-align: center!important;
        font-size: 18px!important;
      }
    }
  }

  .planning-board table th.today,
  .planning-board table td.today {
    background-color: #bbdefb!important;
  }

  .dayoff.bankHoliday,
  .dayoff.bankHoliday {
    background-color: #B2DFDB;
  }

  .noverflow {
    overflow: hidden!important;
  }

  .absent-key {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      font-size: 18px;
      

      span {
        position: relative;
        top: 4px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: none;
        margin-right: 3px;
      }
    }
  }

  .holiday {
    background-color: #00e4e1;
    border: 3px groove #00e4e1;
  }
  .holiday-half {
    background-color: #147d7e;
    border: 3px groove #147d7e;
  }
  .holiday-unpaid {
    background-color: #f2ff04;
    border: 3px groove #f2ff04;
  }
  .holiday-half-unpaid {
    background-color: #b2c640;
    border: 3px groove #b2c640;
  }
  .sick-day {
    background-color: #ff0000;
    border: 3px groove #ff0000;
  }
  .sick-day-half {
    background-color: #d23a3a;
    border: 3px groove #d23a3a;
  }
  .sick-day-unpaid {
    background-color: #f37c37;
    border: 3px groove #f37c37;
  }
  .sick-day-half-unpaid {
    background-color: #b26234;
    border: 3px groove #b26234;
  }
  .compassionate-day {
    background-color: #6600ff;
    border: 3px groove #6600ff;
    color: #FFF;
  }
  .compassionate-day-unpaid {
    background-color: #6e3bb9;
    border: 3px groove #6e3bb9;
    color: #FFF;
  }
  .compassionate-day-half {
    background-color: #9d00ff;
    border: 3px groove #9d00ff;
    color: #FFF;
  }
  .compassionate-day-half-unpaid {
    background-color: #894bb0;
    border: 3px groove #894bb0;
    color: #FFF;
  }
  .parental-day {
    background-color: #25ff06;
    border: 3px groove #25ff06;
  }.parental-day-unpaid {
    background-color: #5daf52;
    border: 3px groove #5daf52;
  }.parental-day-half {
    background-color: #31871c;
    border: 3px groove #31871c;
  }.parental-day-half-unpaid {
    background-color: #2b5322;
    border: 3px groove #2b5322;
    color: #FFF;
  }.awol-day {
    background-color: #0800FF;
    border: 3px groove #0800FF;
    color: #FFF;
  }.awol-day-unpaid {
    background-color: #3b37ab;
    border: 3px groove #3b37ab;
    color: #FFF;
  }.awol-day-half {
    background-color: #16207f;
    border: 3px groove #16207f;
    color: #FFF;
  }.awol-day-half-unpaid {
    background-color: #161b41;
    border: 3px groove #161b41;
    color: #FFF;
  }
.course-day {
    background-color: #8BFF00;
    border: 3px groove #8BFF00;
  }
.course-day-unpaid {
    background-color: #79ac3a;
    border: 3px groove #79ac3a;
  }
.course-day-half {
    background-color: #497510;
    border: 3px groove #497510;
    color: #FFF;
  }
.course-day-half-unpaid {
    background-color: #2d4211;
    border: 3px groove #2d4211;
    color: #FFF;
  }
.suspended-day {
    background-color: #FF00B7;
    border: 3px groove #FF00B7;
  }
.suspended-day-unpaid {
    background-color: #aa2c86;
    border: 3px groove #aa2c86;
  }
.suspended-day-half {
    background-color: #7e095d;
    border: 3px groove #7e095d;
    color: #FFF;
  }
.suspended-day-half-unpaid {
    background-color: #541843;
    border: 3px groove #541843;
    color: #FFF;
  }
  .wfh-day {
    background-color: #975757;
    border: 3px groove #975757;
  }
  .wfh-day-unpaid {
    background-color: #725151;
    border: 3px groove #725151;
  }
  .wfh-day-half {
    background-color: #452424;
    border: 3px groove #452424;
    color: #FFF;
  }
  .wfh-day-half-unpaid {
    background-color: #1e0a0a;
    border: 3px groove #1e0a0a;
    color: #FFF;
  }
  .stood-down-day {
    background-color: #17d89e;
    border: 3px groove #17d89e;
  }
  .stood-down-day-unpaid {
    background-color: #1e4445;
    border: 3px groove #1e4445;
    color: #FFF;
  }
  .stood-down-day-half {
    background-color: #192828;
    border: 3px groove #192828;
    color: #FFF;
  }
  .stood-down-day-half-unpaid {
    background-color: #000000;
    border: 3px groove #000000;
    color: #FFF;
  }
</style>
