<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Manage Bank Holidays</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="addBankHoliday === false">
            <v-col>
              <v-btn @click="addBankHoliday = true" x-small class="ml-2">Add New</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="addBankHoliday === true">
            <v-col>
              <v-text-field v-model="newBankHoliday.name" label="Name"></v-text-field><br />
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newBankHoliday.date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    suffix="*"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <div class="pt-2">
                <v-btn @click="saveBankHoliday" x-small>Save</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="bankHolidayInEdit">
            <v-col>
              <label>Update bank holiday</label>
              <v-text-field v-model="bankHolidayInEdit.name" label="Name"></v-text-field><br />
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="displayEditDate"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    suffix="*"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editDate"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <div class="pt-2">
                <v-btn @click="updateBankHoliday" x-small>Save Changes</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bankHoliday, index) in bankHolidays" :key="`bh_${index}`">
                    <td>{{ bankHoliday.name }}</td>
                    <td>{{ bankHoliday.date | prettyDate }}</td>
                    <td><v-btn @click="editBankHoliday(index)" x-small>Edit</v-btn></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div class="text-right pa-2">
        <v-btn text @click="close">Close</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from 'date-fns';
import axios from '../../../../axios';

export default {
  name: 'PlanningBoardManageBankHolidays',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  data() {
    return {
      dialog: true,
      bankHolidays: [],
      newBankHoliday: {
        name: '',
        date: null,
      },
      date: null,
      editDate: null,
      addBankHoliday: false,
      menu: false,
      bankHolidayInEdit: null,
      displayEditDate: null,
    };
  },
  watch: {
    date () {
      this.newBankHoliday.date = this.formatDate(this.date);
    },
    editDate () {
      this.bankHolidayInEdit.date = this.editDate;
      this.displayEditDate = this.formatDate(this.editDate);
    },
  },
  methods: {
    updateBankHoliday() {
      const postData = {};
      postData.bankHoliday = this.bankHolidayInEdit;
      postData.userid = this.userid;
      axios.post(`/bankHolidays/update.json?token=${this.token}`, postData)
        .then(() => {
          this.bankHolidayInEdit = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editBankHoliday(index) {
      this.bankHolidayInEdit = this.bankHolidays[index];
      this.editDate = format(new Date(this.bankHolidayInEdit.date), 'yyy-MM-d');
    },
    saveBankHoliday() {
      const postData = {};
      postData.newBankHoliday = this.newBankHoliday;
      postData.userid = this.userid;
      axios.post(`/bankHolidays/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.addBankHoliday = false;
          this.newBankHoliday.name = '';
          this.newBankHoliday.date = null;
          this.bankHolidays.push(response.data.newBankHoliday);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    close() {
      this.$emit('closeDialog');
    },
    getBankHolidays() {
      axios.get(`/bankHolidays/getAll.json?token=${this.token}`)
        .then((response) => {
          this.bankHolidays = response.data.bankHolidays;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.getBankHolidays();
  },
};
</script>
