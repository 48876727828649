<template>
    <v-dialog
      v-model="dialog"
      persistent
      width="900"
    >
      <div class="bg-white pa-4">
        <v-row>
          <v-col>
            <h3>
              Update Absence Record for {{ person.first_name }} {{ person.last_name }} on {{ absence.date | prettyDate }}
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select :items="types" label="Type" v-model="absence.type"></v-select>
          </v-col>
        </v-row>
        <v-row v-if="absence.type === 2">
          <v-col>
            <v-select :items="doctorsNote" label="Doctors note?" v-model="absence.doctors_note"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select label="Paid?" :items="paidUnpaid" v-model="absence.paid"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select label="Full Day?" :items="fullHalfDay" v-model="absence.day"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea label="Notes" v-model="absence.note"></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="message.length > 0">
          <v-col>
            <p class="text-red text-heavy">{{ message }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn color="red" text @click="deleteAbsence">Delete</v-btn>&nbsp;
            <v-btn color="green darken-2" text @click="updateAbsence">Save</v-btn>&nbsp;
            <v-btn text @click="closeDialog">Cancel</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </template>

  <script>
  import axios from '../../../../axios';

  export default {
    name: 'PlanningBoardUpdateAbsence',
    props: [
      'absenceId',
      'absenceType',
    ],
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
    },
    data() {
      return {
        absence: {
          day: 1,
          paid: 1,
          note: '',
          type: 0,
          doctors_note: 0,
        },
        originalType: 0,
        dialog: true,
        message: '',
        doctorsNote: [
          { 'text':'Yes', 'value': 1 },
          { 'text': 'No', 'value': 0 },
        ],
        paidUnpaid: [
          { 'text':'Paid', 'value': 1 },
          { 'text': 'UnPaid', 'value': 0 },
        ],
        person: {},
        fullHalfDay: [
          { 'text': 'Full Day', 'value': 1 },
          { 'text': 'Half Day', 'value': 0 },
        ],
        types: [
          { 'text': 'Holiday', 'value': 1 },
          { 'text': 'Sickday', 'value': 2 },
          { 'text': 'Compassionate', 'value': 3 },
          { 'text': 'Paternity/Maternity', 'value': 4 },
          { 'text': 'AWOL', 'value': 5 },
          { 'text': 'Course', 'value': 6 },
          { 'text': 'Suspended', 'value': 7 },
          { 'text': 'Working from Home', 'value': 8 },
          { 'text': 'Stood Down', 'value': 9 },
        ],
      };
    },
    watch: {
      absence: {
        handler() {
          this.message = '';
          /*
          if (value.type === 2) {
            this.absence.doctors_note = 0;
          }
           */
        },
        deep: true,
      },
    },
    methods: {
      deleteAbsence() {
        const postData = {};
        postData.absence = this.absence;
        axios.post(`/personnelPlanner/deleteAbsence.json?token=${this.token}`, postData)
          .then(() => {
            this.$emit('deleteAbsence');
          })
          .catch((error) => {
            console.log(error);
          });
      },
      updateAbsence() {
        if (this.absence.type > 0) {
          const postData = {};
          postData.absence = this.absence;
          postData.userId = this.userid;
          postData.personnelId = this.personnelId;
          postData.date = this.date;
          postData.originalType = this.originalType;
          axios.post(`/personnelPlanner/updateAbsence.json?token=${this.token}`, postData)
            .then(() => {
              this.$emit('updateAbsence');
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.message = 'Please choose a type of absence';
        }
      },
      getAbsence() {
        const postData = {};
        postData.absenceId = this.absenceId;
        postData.absenceType = this.absenceType;
        axios.post(`/personnelPlanner/getAbsence.json?token=${this.token}`, postData)
            .then((response) => {
              console.log(response.data.absence);
              this.originalType = response.data.absence.type;
              this.absence = response.data.absence;
              if (this.absence.sick_date) this.absence.date = this.absence.sick_date;
              if (this.absence.absent_date) this.absence.date = this.absence.absent_date;
              if (this.absence.leave_date) this.absence.date = this.absence.leave_date;
              if (this.absence.holiday_date) this.absence.date = this.absence.holiday_date;
              if (this.absence.sick_date) {
                this.absence.date = this.absence.sick_date;
              }
              this.person = response.data.person;
            })
            .catch((error) => {
              console.log(error);
            });
      },
      closeDialog() {
        this.$emit('closeDialog');
      },
    },
    mounted() {
      this.getAbsence();
    },
  };
  </script>
